import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import { withTrans } from "../../../../i18n/withTrans"
import * as styles from "./Layout.module.scss"

import "../../../../scss/bootstrap.scss"
import "../../../../scss/main.scss"
import "../../../../scss/font-icons.css"
import Navbar from "./navbar"
import { useTranslation } from "react-i18next"

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

const Layout = ({ location, children }) => {
  const { i18n: l } = useTranslation()

  return (
    <>
      <div className={styles.header}>
        <Navbar
          whiteHeader={
            location.pathname.includes("/corsi-di-fotografia/") &&
            location.pathname !== "/corsi-di-fotografia/"
          }
        />
      </div>
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default withTrans(Layout)
