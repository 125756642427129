import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import Button from "/src/components/ui/base/Button"
import { marketingConsent } from "./index.module.scss"

const NewsletterForm = () => {
  const { addToast } = useToasts()
  const [mail, setMail] = useState("")

  const handleChange = e => {
    setMail(e.target.value)
  }

  const onSubmit = async event => {
    event.preventDefault()
    if (emailValidation) {
      await fetch(`${process.env.GATSBY_API_URL}/leads`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: mail }),
      }).then(result => {
        if (result.status === 200) {
          addToast(
            "Yay ✈️, pronto a volare? Controlla la tua email e conferma️",
            {
              appearance: "success",
              autoDismiss: true,
            }
          )
          setMail("")
        } else {
          addToast("Probabilmente sei giá iscritto️", {
            appearance: "warning",
            autoDismiss: true,
          })
        }
      })
    }
  }

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\],;:\s@]+(\.[^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@]{2,})$/i
    if (!mail || regex.test(mail) === false) {
      return false
    }
    return true
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <input
          type="email"
          placeholder="Inserisci la tua email"
          className="form-control"
          value={mail}
          onChange={handleChange}
          required
        />
      </div>
      <div className={marketingConsent}>
        Iscrivendoti accetti la{" "}
        <a
          href={"https://www.iubenda.com/privacy-policy/43774671/cookie-policy"}
          target="_blank"
        >
          privacy policy
        </a>{" "}
        e di ricevere consigli di fotografia e informazioni sui prossimi viaggi
        e corsi.
      </div>
      <Button variant={"link"} submit>
        Iscriviti
      </Button>
    </form>
  )
}

export default NewsletterForm
