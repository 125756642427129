// extracted by mini-css-extract-plugin
export var root = "index-module--root--1TPlV";
export var display1 = "index-module--display1--CS0d9";
export var display2 = "index-module--display2--1KQnD";
export var display3 = "index-module--display3--Oid2m";
export var heading1 = "index-module--heading1--yEKVD";
export var heading2 = "index-module--heading2--+78ZY";
export var heading3 = "index-module--heading3--fRFHP";
export var heading4 = "index-module--heading4--Y3rF0";
export var heading5 = "index-module--heading5--FEUNh";
export var heading6 = "index-module--heading6--dhnNQ";
export var intro = "index-module--intro--kUJrR";
export var body = "index-module--body--E8BkM";
export var small = "index-module--small--fOjrs";
export var large = "index-module--large--QGGU2";
export var handwritten = "index-module--handwritten--7igpA";
export var blockquote = "index-module--blockquote--aonsP";
export var paragraph = "index-module--paragraph--BW3Pi";
export var noWrap = "index-module--noWrap--Gy0RM";
export var headingText = "index-module--headingText--Pt5l0";
export var headingTitle = "index-module--headingTitle--C+nCv";
export var headingSubTitle = "index-module--headingSubTitle--AQLlm";
export var iconText = "index-module--iconText--LOy6V";
export var subHeadingTitle = "index-module--subHeadingTitle--vyVtm";
export var subTitle = "index-module--subTitle--xBkn-";
export var iosHeading = "index-module--iosHeading--ofV2D";
export var formDescription = "index-module--formDescription--asEZA";
export var cardSubTitle = "index-module--cardSubTitle--tvDit";
export var cardTitle = "index-module--cardTitle--emmcs";
export var cardHeadingText = "index-module--cardHeadingText--rzzt7";
export var cardName = "index-module--cardName--4adey";
export var headerIntro = "index-module--headerIntro--CZJZ2";
export var headerText = "index-module--headerText--qs0ZI";