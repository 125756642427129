export const PHOTO_TOURS_URL = {
  it: "viaggi-fotografici",
  en: "photo-tours",
}

export const COLLECTIONS_URL = {
  it: "collezioni",
  en: "collections",
}

export const PHOTOGRAPHERS_URL = {
  it: "fotografi",
  en: "photographers",
}

export const STORY_URL = {
  it: "storie",
  en: "stories",
}

export const REVIEWS_URL = {
  it: "recensioni",
  en: "reviews",
}

export const DESTINATIONS_URL = {
  it: "destinazioni",
  en: "destinations",
}

export const CALENDAR_URL = {
  it: "calendario",
  en: "calendar",
}

export const COURSES_URL = {
  it: "corsi-di-fotografia",
  en: "photography-courses",
}

export const ACADEMY_URL = {
  it: "accademia-di-fotografia",
  en: "photography-academy",
}

export const BASE_URL = {
  it: "https://www.weshoot.it",
  en: "https://www.weshoot.tour",
}
