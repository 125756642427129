import classnames from "classnames"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { ToastProvider } from "react-toast-notifications"
import Typography from "/src/components/ui/base/typography"
import Link from "/src/components/ui/base/Link"
import * as styles from "./index.module.scss"
import Newsletter from "/src/components/ui/base/NewsletterForm"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { COURSES_URL } from "../../../../../util/constants"

const Footer = () => {
  const { i18n: l } = useTranslation()
  const data = useStaticQuery(query)
  const courses = data.allStrapiCourse.edges

  const [values, setValues] = useState({
    language: "it",
  })

  function handleChange(event) {
    l.changeLanguage(event.target.value)

    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <footer className={classnames([styles.container])}>
      <Container>
        <Row>
          <Col md={3}>
            <Typography
              variant="heading3"
              className={classnames([styles.title])}
            >
              <span style={{ color: "#ff4424" }}>We</span>Shoot
            </Typography>
            <ul>
              <li>
                <Link
                  to="/viaggi-fotografici/"
                  title="Viaggi Fotografici WeShoot"
                >
                  ✈️ Viaggi Fotografici
                </Link>
              </li>
              <li>
                <Link
                  to="/viaggi-fotografici/calendario/"
                  title="Calendario Viaggi Fotografici WeShoot"
                >
                  📅 Calendario Viaggi Fotografici
                </Link>
              </li>
              <li>
                <Link to="/fotografi/" title=" Fotografi Coach WeShoot">
                  🦸🏻‍ Scopri i tuoi coach
                </Link>
              </li>
              <li>
                <Link to="/recensioni/" title="Recensioni WeShooters">
                  ⭐️ Recensioni
                </Link>
              </li>
              <li>
                <Link
                  to="/accademia-di-fotografia/"
                  title="Accademia Fotografia online"
                >
                  📚 Accademia di Fotografia
                </Link>
              </li>
              <li>
                <Link
                  to="/corsi-di-fotografia/"
                  title="Corsi Fotografia Online"
                >
                  🎥 Corsi di Fotografia
                </Link>
              </li>
              <li>
                <Link to="https://www.weshoot.it/accademia/sistema-weshoot/">
                  👨‍🎓 Diventa un master
                </Link>
              </li>
              <li>
                <Link to="https://www.iubenda.com/privacy-policy/43774671">
                  🔐 Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/gdpr">🇪🇺 GDPR</Link>
              </li>
              <li>
                <Link to="/terms">📜 Termini e condizioni</Link>
              </li>
              <li>
                <Link to="https://www.iubenda.com/privacy-policy/43774671/cookie-policy">
                  🍪 Cookie Policy
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <Typography
              variant="heading3"
              className={classnames([styles.title])}
            >
              Corsi di Fotografia
            </Typography>
            <ul>
              {courses.map(course => {
                return (
                  <li key={`course-${course.node.slug}`}>
                    <Link
                      to={`/${COURSES_URL[l.language]}/${course.node.slug}`}
                    >
                      {course.node.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Col>
          <Col md={3}>
            <Typography
              variant="heading3"
              className={classnames([styles.title])}
            >
              Blog
            </Typography>
            <ul>
              <li>
                <Link
                  to="https://www.weshoot.it/blog"
                  title="Blog di fotografia WeShoot"
                >
                  Blog di Fotografia
                </Link>
              </li>
              <li>
                <Link to="https://www.weshoot.it/blog/category/tutorial/">
                  Tutorials
                </Link>
              </li>
              <li>
                <Link to="https://www.weshoot.it/blog/category/rumors/">
                  Rumors
                </Link>
              </li>
              <li>
                <Link to="https://www.weshoot.it/blog/category/scuola-di-fotografia/">
                  Scuola di fotografia
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <Typography
              variant="heading3"
              className={classnames([styles.title])}
            >
              UNISCITI A 18.000 WeShooters
            </Typography>
            <Typography variant="heading6">
              Ricevi solo le notizie per migliorare la tua fotografia di
              paesaggio.
            </Typography>
            <ToastProvider placement="bottom-center">
              <Newsletter />
            </ToastProvider>
            {/*<Typography variant='heading3' className={classnames([styles.title])}>*/}
            {/*    Lingua*/}
            {/*</Typography>*/}
            {/*<div className={classnames([styles.setting])}>*/}
            {/*    <select name="language" id="language" value={values.language}*/}
            {/*        onChange={(e) => handleChange(e)}*/}
            {/*    >*/}
            {/*        <option value="it">🇮🇹 Italiano</option>*/}
            {/*        <option value="en">🇺🇸 English</option>*/}
            {/*    </select>*/}
            {/*</div>*/}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className={classnames([styles.social_footer])}>
              <ul>
                <li>
                  <Link
                    title="WeShoot Facevook"
                    to="https://www.facebook.com/groups/fotografiaindigitale"
                  >
                    <i className="icon-facebook-official" />
                  </Link>
                </li>
                <li>
                  <Link
                    title="WeShoot instagram"
                    to="https://www.instagram.com/we_shoot_official/"
                  >
                    <i className="icon-instagram" />
                  </Link>
                </li>
                <li>
                  <Link
                    title="WeShoot youtube"
                    to="https://www.youtube.com/channel/UCyAcVGdUpxmgM7s2EjrVVkga"
                  >
                    <i className="icon-youtube" />
                  </Link>
                </li>
              </ul>
              <p>
                © WeShoot 2021 - Tutti i diritti riservati - P.IVA IT14945891001
                - Creato da Roadtrip Technologies LTD{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer

export const query = graphql`
  {
    allStrapiCourse {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
