module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/application/src/components/ui/base/layout/Layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"220965505676374"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e2145b40c5f94addac81b3c943520b1d@o1214221.ingest.sentry.io/6354704","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MRH76BZ","includeInDevelopment":true,"routeChangeEventName":"page_view","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManagerOptions":true,"iubendaOptions":{"consentOnContinuedBrowsing":true,"lang":"it","siteId":1111146,"floatingPreferencesButtonDisplay":false,"enableCMP":true,"googleAdditionalConsentMode":true,"isTCFConsentGlobal":false,"cookiePolicyId":43774671,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-center","backgroundOverlay":true,"rejectButtonDisplay":true}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
