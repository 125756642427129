const { EVENT_PAGE_VIEW } = require("./src/constants/events");

exports.onRouteUpdate = ({ location }) => {

  if (window) {
    if (window.customerly) {
      window.customerly.update();
    }

    if (window.mixpanel) {
      window.mixpanel.track(EVENT_PAGE_VIEW, location);
    }
  }
};